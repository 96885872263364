/* General Animations */

#root {
    position: absolute;
}

/* wrappers & containers */
.wrapper {
  align-items: center;
}


/* card */
.card {
  background-color: #FFF0;
  word-wrap: break-word;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  transition: .5s;
  text-transform: uppercase;
}

.card img {
    border-radius: 50%;
}

.close {
  border-radius: 50%;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  float: right;
  top: -228px;
  right: -6px;
  font-size: 2em;
  position: relative;
  color: #fff;
  transition: .5s;
}

.close:hover {
  cursor: pointer;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

/* Loading Icon */
.loading-wrapper, .loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading {
  width: 200px;
  height: 200px;
}

.loading .background {
  border-radius: 50%;
  background: #6762a6;
  border: 3px solid #c9c3e6;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  animation: pulse-colors 2s infinite alternate linear;
}

.loading i {
  margin: 25px 5px 5px 55px;
  float: left;
  font-size: 10em !important;
  color: #fff;
  animation: pulse-icon 2s infinite alternate linear;
}

button.button.upstairs, 
button.button.downstairs {
    margin-bottom:10px;
}
